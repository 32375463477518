<template>
  <el-dialog
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <top-form ref="topForm" :form="topForm"></top-form>
      <div class="table" style="text-align: center">
        <el-row
          :gutter="20"
          style="line-height: 35px; color: '#606266'; background-color: #f5f7fa"
        >
          <el-col :span="6">类型1</el-col>
          <el-col :span="6">开通数量</el-col>
          <el-col :span="6">赠送数量</el-col>
          <el-col :span="6">试用上限</el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="6">
            <div class="grid-content" style="line-height: 35px">开通端口</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="topForm.port_add"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="topForm.port_gift"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="topForm.port_test"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="table" style="text-align: center">
        <el-row
          :gutter="20"
          style="line-height: 35px; color: '#606266'; background-color: #f5f7fa"
        >
          <el-col :span="6">类型2</el-col>
          <el-col :span="6">时长(月)</el-col>
          <el-col :span="6">赠送(月)</el-col>
          <el-col :span="6">金额</el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="6">
            <div class="grid-content" style="line-height: 35px">签约时长</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="topForm.time_add"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="topForm.time_gift"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="topForm.time_money"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="table" style="text-align: center">
        <el-row
          :gutter="20"
          style="line-height: 35px; color: '#606266'; background-color: #f5f7fa"
        >
          <el-col :span="6">类型3</el-col>
          <el-col :span="6">网销开关</el-col>
          <el-col :span="6">门店上限</el-col>
          <el-col :span="6">金额</el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="6">
            <div class="grid-content" style="line-height: 35px">网销</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <!-- <el-input v-model="topForm.mall_falg"></el-input> -->
              <el-switch
                v-model="topForm.mall_falg"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="topForm.mall_limit"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="topForm.mall_money"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="table" style="text-align: center">
        <el-row
          :gutter="20"
          style="line-height: 35px; color: '#606266'; background-color: #f5f7fa"
        >
          <el-col :span="4">类型4</el-col>
          <el-col :span="5">培训日期</el-col>
          <el-col :span="5">培训人</el-col>
          <el-col :span="5">天数</el-col>
          <el-col :span="5">金额</el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="4">
            <div class="grid-content" style="line-height: 35px">培训</div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content" style="line-height: 35px">
              <!-- teach_date -->
              <el-date-picker
                v-model="topForm.teach_date"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                style="width: 100%"
              ></el-date-picker>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <!-- <el-input v-model="topForm.teach_user"></el-input> -->
              <el-select
                v-model="topForm.teach_user"
                clearable
                style="width: 100%"
                placeholder="培训人"
              >
                <el-option
                  v-for="(i, idx) in peixunrenList"
                  :key="idx"
                  :value="i.id"
                  :label="i.username"
                ></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <el-input v-model="topForm.teach_day"></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <el-input v-model="topForm.teach_money"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="hanldeCreate">创建</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import TopForm from './topForm'
  import {
    guishuSelectList,
    createFormalUser,
  } from '@/api/admin/userManagement'
  import md5 from 'md5'
  export default {
    name: 'Create',
    components: { TopForm },
    data() {
      return {
        title: '添加正式用户',
        showDialog: false,
        peixunrenList: [],
        topForm: {
          dealer_name: '',
          id: '',
          boss: '',
          mobile: '',
          phone: '',
          guimo: '',
          leixing: '',
          hangye: '',
          province: '',
          city: '',
          area: '',
          address: '',
          login_user: '',
          password: '',
          start_time: '',
          end_time: '',
          from: '',
          parent_name: '',
          vest_in: '',
          // table1
          port_add: '',
          port_gift: '',
          port_test: '',
          // table2
          time_add: '',
          time_gift: '',
          time_money: '',
          // table3
          mall_falg: 1,
          mall_limit: '',
          mall_money: '',
          // table4
          teach_date: '',
          teach_user: '',
          teach_day: '',
          teach_money: '',
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (!val) {
          this.$refs.topForm.clearForm()
          this.topForm = {
            dealer_name: '',
            id: '',
            boss: '',
            mobile: '',
            phone: '',
            guimo: '',
            leixing: '',
            hangye: '',
            province: '',
            city: '',
            area: '',
            address: '',
            login_user: '',
            password: '',
            start_time: '',
            end_time: '',
            from: '',
            parent_name: '',
            vest_in: '',
            // table1
            port_add: '',
            port_gift: '',
            port_test: '',
            // table2
            time_add: '',
            time_gift: '',
            time_money: '',
            // table3
            mall_falg: 1,
            mall_limit: '',
            mall_money: '',
            // table4
            teach_date: '',
            teach_user: '',
            teach_day: '',
            teach_money: '',
          }
        }
      },
    },
    created() {},
    mounted() {
      // 培训人下拉
      guishuSelectList({ pageSize: -1 }).then((res) => {
        this.peixunrenList = res.data
      })
    },
    methods: {
      hanldeCreate() {
        // console.log(this.$refs.topForm.formatForm())
        this.$refs.topForm.formatForm()
        if (this.$refs.topForm.formSucc) {
          const topForm = JSON.parse(JSON.stringify(this.topForm))
          topForm.hangye = topForm.hangye.join(',')
          topForm.password = md5(topForm.password) || ''
          console.log(topForm)
          createFormalUser(topForm).then((res) => {
            console.log(res)
            this.$message.success(res.msg)
            this.close()
            this.$emit('refresh')
          })
        } else {
        }
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .table {
    margin-bottom: 10px;
  }
</style>
