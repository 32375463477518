<template>
  <!-- NAME[epic=admin] 正式用户 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <el-form-item label="" prop="keyword">
        <el-input v-model="form.keyword" placeholder="企业ID或名称"></el-input>
      </el-form-item>
      <el-form-item label="" prop="name">
        <el-input v-model="form.name" placeholder="联系姓名或手机号"></el-input>
      </el-form-item>
      <el-form-item label="" prop="area_id">
        <el-cascader
          v-model="form.area_id"
          filterable
          placeholder="地区"
          :options="areaSelect"
          :props="{
            checkStrictly: true,
            emitPath: false,
            value: 'id',
            label: 'title',
          }"
          clearable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="" prop="vest_in">
        <el-select
          v-model="form.vest_in"
          clearable
          style="width: 140px"
          placeholder="归属"
        >
          <el-option
            v-for="(i, idx) in vestList"
            :key="idx"
            :value="i.id"
            :label="i.truename"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="" prop="parent_name">
        <el-input
          v-model="form.parent_name"
          style="width: 150px"
          placeholder="推荐人姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="from">
        <el-select
          v-model="form.from"
          clearable
          style="width: 120px"
          placeholder="来源"
        >
          <el-option
            v-for="(i, idx) in fromList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="status">
        <el-select
          v-model="form.status"
          clearable
          style="width: 140px"
          placeholder="状态"
        >
          <el-option
            v-for="(i, idx) in statusList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <!-- 加参数 time_type 时间类型  1创建时间  2到期时间  默认取2
两个时间参数 start_time 和 end_time  示例: 2022-10-16 -->
        <el-select v-model="form.time_type" clearable placeholder="时间类型">
          <el-option label="创建时间" value="1"></el-option>
          <el-option label="到期时间" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-badge :value="expire">
          <el-button type="warning" @click="fetchData">即将到期</el-button>
        </el-badge>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="success" @click="handleCreate">创建</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="" @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ $index, row }">
          <el-button
            v-if="row.is_close == 1"
            type="text"
            @click.native.prevent="handleCheck($index, row)"
          >
            查看
          </el-button>
          <el-button
            type="text"
            @click.native.prevent="handleLogin($index, row)"
          >
            登入
          </el-button>
          <template v-if="row.is_close != 1">
            <el-button
              type="text"
              @click.native.prevent="handleEdit($index, row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="handleAccount($index, row)"
            >
              账密
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="handleStaff($index, row)"
            >
              员工
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="handleClear($index, row)"
            >
              清除数据(留基础)
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="handleRenewal($index, row)"
            >
              续费
            </el-button>

            <el-button
              type="text"
              @click.native.prevent="handleCustDetail($index, row)"
            >
              客情
            </el-button>
          </template>
          <el-button
            v-if="row.is_close == 0"
            type="text"
            @click.native.prevent="handleChangeStatus($index, row)"
          >
            封存
          </el-button>
          <el-button
            v-if="row.is_close == 1"
            type="text"
            @click.native.prevent="handleChangeStatus($index, row)"
          >
            启用
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageWrap">
      <div class="total">合计:{{ allTotal }}</div>
      <div class="page">
        <el-pagination
          :current-page="form.pageNo"
          :layout="layout"
          :page-size="form.pageSize"
          :total="total"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
    </div>
    <edit ref="edit" @refresh="fetchData"></edit>
    <create ref="create" @refresh="fetchData"></create>
    <check ref="check"></check>
    <staff ref="staff" @refresh="fetchData"></staff>
    <account ref="account"></account>
    <renewal ref="renewal" @refresh="fetchData"></renewal>
    <cust-detail ref="custDetail"></cust-detail>
  </div>
</template>
<script>
  import _ from 'lodash'
  import Edit from './components/edit'
  import Create from './components/create'
  import Check from './components/check'
  import Staff from './components/staff'
  import Renewal from './components/renewal'
  import CustDetail from './components/custDetail'
  import store from '@/store'
  import {
    formalUserList,
    areaSelectList,
    guishuSelectList,
    formalUserChangeStatus,
  } from '@/api/admin/userManagement.js'
  import Account from './components/account.vue'
  import { downloadFile, postAction } from '@/api/Employee'
  export default {
    name: 'FormalUser',
    components: { Edit, Create, Check, Staff, Account, Renewal, CustDetail },
    data() {
      return {
        time: [],
        loading: false,
        areaSelect: [],
        allTotal: 0,
        //状态 0正常 1已封存 2即将到期 3已过期
        statusList: [
          {
            id: 0,
            name: '正常',
          },
          {
            id: 1,
            name: '已封存',
          },
          {
            id: 2,
            name: '即将到期',
          },
          {
            id: 3,
            name: '已过期',
          },
        ],
        // 来源 1APP2注册3工作站4转介绍
        fromList: [
          {
            id: 1,
            name: 'APP',
          },
          {
            id: 2,
            name: '注册',
          },
          {
            id: 3,
            name: '工作站',
          },
          {
            id: 4,
            name: '转介绍',
          },
        ],
        vestList: [],
        form: {
          time_type: '2',
          pageSize: 10, //
          pageNo: 1, //
          area_id: '', //地区ID
          status: '', //状态 0正常 1已封存 2即将到期 3已过期
          from: '', //来源 1APP2注册3工作站4转介绍
          vest_in: '', //归属ID
          keyword: '', //企业ID或名称
          name: '', //负责人姓名或手机号
          parent_name: '', //推荐人姓名
        },
        // 即将到期
        expire: 0,
        list: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        checkList: [
          '客户ID',
          '企业名称',
          '联系人',
          '手机',
          '状态',
          '到期日期',
          '注册日期',
          '剩余时间',
          '归属',
          '累计付费',
          '地区',
          '地址',
        ],
        columns: [
          {
            order: 1,
            label: '客户ID',
            prop: 'dealer_id',
            width: '',
          },
          {
            order: 2,
            label: '企业名称',
            prop: 'comp',
            width: '',
          },
          {
            order: 3,
            label: '联系人',
            prop: 'boss',
            width: '',
          },
          {
            order: 4,
            label: '手机',
            prop: 'mobile',
            width: '',
          },
          {
            order: 5,
            label: '状态',
            prop: 'is_close_text',
            width: '',
          },
          {
            order: 6,
            label: '到期日期',
            prop: 'end_time',
            width: '',
          },
          {
            order: 7,
            label: '注册日期',
            prop: 'create_at',
            width: '',
          },
          {
            order: 7.5,
            label: '剩余时间',
            prop: 'exprise_day',
            width: '',
          },
          {
            order: 8,
            label: '归属',
            prop: 'vest_name',
            width: '',
          },
          {
            order: 9,
            label: '累计付费',
            prop: ' total_amount',
            width: '',
          },
          {
            order: 10,
            label: '地区',
            prop: 'sheng',
            width: '',
          },
          {
            order: 11,
            label: '地址',
            prop: 'address',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(v) {
        if (v) {
          this.form.start_date = v[0]
          this.form.end_date = v[1]
        } else {
          this.form.start_date = ''
          this.form.end_date = ''
        }
      },
    },
    created() {},
    mounted() {
      this.initAreaSelect()
      this.initVestSelect()
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await formalUserList(this.form)
        console.log('data', data)
        this.list = data.rows
        this.total = totalCount || 0
        this.allTotal = data.total || 0
        this.expire = Number(data.total_dq)
        this.loading = false
      },
      async initAreaSelect() {
        postAction('/baseAdmin/common/province', {})
          .then((res) => {
            console.log(res, '')
            this.areaSelect = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      async initVestSelect() {
        let { data } = await guishuSelectList({ pageSize: -1 })
        this.vestList = data
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      // 创建
      handleCreate() {
        this.$refs.create.showDialog = true
      },
      // 编辑
      handleEdit(index, row) {
        this.$refs.edit.id = row.dealer_id
        this.$refs.edit.showDialog = true
      },
      // 查看
      handleCheck(index, row) {
        this.$refs.check.id = row.dealer_id
        this.$refs.check.showDialog = true
      },
      // 员工
      handleStaff(index, row) {
        this.$refs.staff.form.dealer_id = row.dealer_id
        this.$refs.staff.showDialog = true
      },
      // 改变状态
      handleChangeStatus(index, row) {
        formalUserChangeStatus({ dealer_id: row.dealer_id }).then((res) => {
          this.$message.success(res.msg)
          this.fetchData()
        })
      },
      // 登入
      async handleLogin(idx, row) {
        // await store.dispatch('socket/sendWs', 123)
        // console.log('返回的消息是:', store.state.socket.msg)
        let hasToken = store.getters['user/accessToken']
        console.log(hasToken, 'hasToken1')
        let a = hasToken.split('*')
        console.log(a, 'hasToken2', row.user_token)
        let t = ''
        if (a.length == 1) {
          t = row.user_token + '*' + hasToken
        } else {
          t = row.user_token + '*' + a[1]
        }
        console.log(t)
        // this.setAccessToken(t)
        // console.log(this.$store)

        // store.getters['user/setAccessToken']
        store.commit('user/setAccessToken', t)
        // console.log(store.getters['user/accessToken'])
        this.$router.push('/')
        location.reload()
        // window.open('/')
      },
      // 账户
      handleAccount(index, row) {
        console.log(row)
        this.$refs.account.title =
          '企业:' + row.dealer_name + ' (id:' + row.dealer_id + ')'
        this.$refs.account.id = row.dealer_id
        this.$refs.account.showDialog = true
      },
      // 续费
      handleRenewal(index, row) {
        this.$refs.renewal.id = row.dealer_id
        this.$refs.renewal.showDialog = true
      },
      // 客情
      handleCustDetail(index, row) {
        this.$refs.custDetail.id = row.dealer_id
        this.$refs.custDetail.showDialog = true
      },
      // 清除数据
      handleClear(index, row) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            postAction('/superAdmin/dealer-real/clear', {
              dealer_id: row.dealer_id,
            }).then((r) => {
              this.$message.success('清除成功')
              this.fetchData()
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handleExport() {
        console.log('$')
        downloadFile(
          '/superAdmin/dealer-real/index-export',
          '正式用户.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped>
  .pageWrap {
    display: flex;
    justify-content: space-between;
    .total {
      font-size: 18px;
      line-height: 70px;
    }
  }
</style>
