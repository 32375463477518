var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "keyword" } },
            [
              _c("el-input", {
                attrs: { placeholder: "企业ID或名称" },
                model: {
                  value: _vm.form.keyword,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "keyword", $$v)
                  },
                  expression: "form.keyword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "联系姓名或手机号" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "area_id" } },
            [
              _c("el-cascader", {
                attrs: {
                  filterable: "",
                  placeholder: "地区",
                  options: _vm.areaSelect,
                  props: {
                    checkStrictly: true,
                    emitPath: false,
                    value: "id",
                    label: "title",
                  },
                  clearable: "",
                },
                model: {
                  value: _vm.form.area_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "area_id", $$v)
                  },
                  expression: "form.area_id",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "vest_in" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "归属" },
                  model: {
                    value: _vm.form.vest_in,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vest_in", $$v)
                    },
                    expression: "form.vest_in",
                  },
                },
                _vm._l(_vm.vestList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.truename },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "parent_name" } },
            [
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { placeholder: "推荐人姓名" },
                model: {
                  value: _vm.form.parent_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "parent_name", $$v)
                  },
                  expression: "form.parent_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "from" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { clearable: "", placeholder: "来源" },
                  model: {
                    value: _vm.form.from,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "from", $$v)
                    },
                    expression: "form.from",
                  },
                },
                _vm._l(_vm.fromList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "状态" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "时间类型" },
                  model: {
                    value: _vm.form.time_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "time_type", $$v)
                    },
                    expression: "form.time_type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "创建时间", value: "1" } }),
                  _c("el-option", { attrs: { label: "到期时间", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function () {
                      _vm.form.pageSize = 10
                      _vm.form.pageNo = 1
                      _vm.fetchData()
                    },
                  },
                },
                [_vm._v(" 查询 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-badge",
                { attrs: { value: _vm.expire } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning" },
                      on: { click: _vm.fetchData },
                    },
                    [_vm._v("即将到期")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.handleCreate } },
                [_vm._v("创建")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "" }, on: { click: _vm.handleExport } },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _vm._l(_vm.finallyColumns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: "",
                align: "center",
              },
            })
          }),
          _c("el-table-column", {
            attrs: {
              prop: "",
              align: "center",
              label: "操作",
              fixed: "right",
              "min-width": "160px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  var row = ref.row
                  return [
                    row.is_close == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleCheck($index, row)
                              },
                            },
                          },
                          [_vm._v(" 查看 ")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleLogin($index, row)
                          },
                        },
                      },
                      [_vm._v(" 登入 ")]
                    ),
                    row.is_close != 1
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleEdit($index, row)
                                },
                              },
                            },
                            [_vm._v(" 编辑 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleAccount($index, row)
                                },
                              },
                            },
                            [_vm._v(" 账密 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleStaff($index, row)
                                },
                              },
                            },
                            [_vm._v(" 员工 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleClear($index, row)
                                },
                              },
                            },
                            [_vm._v(" 清除数据(留基础) ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleRenewal($index, row)
                                },
                              },
                            },
                            [_vm._v(" 续费 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleCustDetail($index, row)
                                },
                              },
                            },
                            [_vm._v(" 客情 ")]
                          ),
                        ]
                      : _vm._e(),
                    row.is_close == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleChangeStatus($index, row)
                              },
                            },
                          },
                          [_vm._v(" 封存 ")]
                        )
                      : _vm._e(),
                    row.is_close == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleChangeStatus($index, row)
                              },
                            },
                          },
                          [_vm._v(" 启用 ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("div", { staticClass: "pageWrap" }, [
        _c("div", { staticClass: "total" }, [
          _vm._v("合计:" + _vm._s(_vm.allTotal)),
        ]),
        _c(
          "div",
          { staticClass: "page" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.form.pageNo,
                layout: _vm.layout,
                "page-size": _vm.form.pageSize,
                total: _vm.total,
                background: "",
              },
              on: {
                "current-change": _vm.handleCurrentChange,
                "size-change": _vm.handleSizeChange,
              },
            }),
          ],
          1
        ),
      ]),
      _c("edit", { ref: "edit", on: { refresh: _vm.fetchData } }),
      _c("create", { ref: "create", on: { refresh: _vm.fetchData } }),
      _c("check", { ref: "check" }),
      _c("staff", { ref: "staff", on: { refresh: _vm.fetchData } }),
      _c("account", { ref: "account" }),
      _c("renewal", { ref: "renewal", on: { refresh: _vm.fetchData } }),
      _c("cust-detail", { ref: "custDetail" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }