<template>
  <div class="orderTest-container">
    <el-dialog
      :modal="false"
      title="用户续费"
      :visible.sync="showDialog"
      width="1000px"
      top="5vh"
      :close-on-click-modal="false"
      destroy-on-close
      center
    >
      <div class="orderTest-container">
        <el-form
          ref="form"
          :model="form"
          inline
          label-position="right"
          label-width="80px"
        >
          <el-form-item label="企业名称:" prop="dealer_name">
            <el-input
              v-model="form.dealer_name"
              style="width: 220px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="ID:" prop="dealer_id">
            <el-input
              v-model="form.dealer_id"
              style="width: 220px"
              disabled
            ></el-input>
          </el-form-item>
          <div></div>
          <el-form-item label="开通日期:" prop="start_time">
            <el-date-picker
              v-model="form.start_time"
              type="date"
              disabled
              placeholder="开通日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="到期日期:" prop="end_time">
            <el-date-picker
              v-model="form.end_time"
              type="date"
              disabled
              placeholder="到期日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-form>
        <div class="table" style="text-align: center">
          <el-row
            :gutter="20"
            style="
              line-height: 35px;
              color: '#606266';
              background-color: #f5f7fa;
            "
          >
            <el-col :span="4">类型1</el-col>
            <el-col :span="5">现有数量</el-col>
            <el-col :span="5">增加数量</el-col>
            <el-col :span="5">赠送数量</el-col>
            <el-col :span="5">金额</el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 10px">
            <el-col :span="4">
              <div class="grid-content" style="line-height: 35px">添加端口</div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content" style="line-height: 35px">
                {{ form.account_num }}
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content">
                <el-input v-model="subForm.port_add"></el-input>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content">
                <el-input v-model="subForm.port_gift"></el-input>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content">
                <el-input v-model="subForm.port_money"></el-input>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="table" style="text-align: center">
          <el-row
            :gutter="20"
            style="
              line-height: 35px;
              color: '#606266';
              background-color: #f5f7fa;
            "
          >
            <el-col :span="4">类型2</el-col>
            <el-col :span="5">当前到期时间</el-col>
            <el-col :span="5">续期(月)</el-col>
            <el-col :span="5">赠送(月)</el-col>
            <el-col :span="5">金额</el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 10px">
            <el-col :span="4">
              <div class="grid-content" style="line-height: 35px">续期</div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content" style="line-height: 35px">
                {{ form.end_time }}
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content">
                <el-input v-model="subForm.time_add"></el-input>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content">
                <el-input v-model="subForm.time_gift"></el-input>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content">
                <el-input v-model="subForm.time_money"></el-input>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="table" style="text-align: center">
          <el-row
            :gutter="20"
            style="
              line-height: 35px;
              color: '#606266';
              background-color: #f5f7fa;
            "
          >
            <el-col :span="4">类型3</el-col>
            <el-col :span="5">网销开关</el-col>
            <el-col :span="5">现门店上限</el-col>
            <el-col :span="5">升级后</el-col>
            <el-col :span="5">金额</el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 10px">
            <el-col :span="4">
              <div class="grid-content" style="line-height: 35px">网销升级</div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content">
                <!-- <el-input v-model="form.mall_falg"></el-input> -->
                <el-switch
                  v-model="subForm.mall_falg"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                ></el-switch>
              </div>
            </el-col>
            <!-- 门店上限 -->
            <el-col :span="5">
              <div class="grid-content" style="line-height: 35px">
                {{ form.mall_limit }}
              </div>
            </el-col>
            <!-- 升级后 -->
            <el-col :span="5">
              <div class="grid-content">
                <el-input v-model="subForm.mall_limit"></el-input>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content">
                <el-input v-model="subForm.mall_money"></el-input>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="table" style="text-align: center">
          <el-row
            :gutter="20"
            style="
              line-height: 35px;
              color: '#606266';
              background-color: #f5f7fa;
            "
          >
            <el-col :span="4">类型4</el-col>
            <el-col :span="5">培训日期</el-col>
            <el-col :span="5">培训人</el-col>
            <el-col :span="5">天数</el-col>
            <el-col :span="5">金额</el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 10px">
            <el-col :span="4">
              <div class="grid-content" style="line-height: 35px">培训</div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content" style="line-height: 35px">
                <el-date-picker
                  v-model="subForm.teach_date"
                  type="date"
                  placeholder="培训日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                ></el-date-picker>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content">
                <el-select
                  v-model="subForm.teach_user"
                  clearable
                  style="width: 100%"
                  placeholder="培训人"
                >
                  <el-option
                    v-for="(i, idx) in peixunrenList"
                    :key="idx"
                    :value="i.id"
                    :label="i.username"
                  ></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content">
                <el-input v-model="subForm.teach_day"></el-input>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content">
                <el-input v-model="subForm.teach_money"></el-input>
              </div>
            </el-col>
          </el-row>
        </div>
        <div style="margin-top: 10px; text-align: right">
          <el-button type="primary" @click="handleSubmit">确定</el-button>
          <el-button @click="close">取消</el-button>
        </div>
        <el-table stripe :data="list" style="margin-top: 10px">
          <el-table-column
            prop="bill_code"
            label="订单号"
            width=""
            align="center"
          ></el-table-column>
          <el-table-column
            prop="create_at"
            label="收款日期"
            width=""
            align="center"
          ></el-table-column>
          <el-table-column
            prop="total_amount"
            label="金额"
            width=""
            align="center"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import {
    userRenewalData,
    userRenewalSubmit,
    guishuSelectList,
  } from '@/api/admin/userManagement'
  export default {
    name: 'Renewal',
    components: {},
    data() {
      return {
        id: '',
        loading: false,
        showDialog: false,
        peixunrenList: [],
        form: {
          dealer_name: '企业名称',
          start_time: '开始时间',
          end_time: '',
          account_num: '',
          mall_falg: '',
          mall_limit: '',
        },
        subForm: {
          dealer_id: '', // 经销商ID
          port_add: '', // 端口数
          port_gift: '', // 端口赠送数
          port_test: '', // 试用端口上限
          port_money: '', // 端口金额
          time_add: '', // 时长(月)
          time_gift: '', // 赠送时长(月)
          time_money: '', // 时长金额
          mall_falg: '', // 网销开关1开2关
          mall_limit: '', // 网销门店上限
          mall_money: '', // 网销金额
          teach_date: '', // 培训日期 如2021-5-5
          teach_user: '', // 培训人ID
          teach_day: '', // 培训天数
          teach_money: '', // 培训金额
          bill_type: [], // 订单类型 逗号分隔 如2,3,4
        },
        list: [
          {
            id: '',
            bill_code: '订单编号',
            total_amount: '金额',
            create_at: '时间',
          },
        ],
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        }
      },
    },
    created() {
      // 培训人下拉
      guishuSelectList({ pageSize: -1 }).then((res) => {
        this.peixunrenList = res.data
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await userRenewalData({ dealer_id: this.id })
        this.form = data
        this.subForm.mall_falg = data.mall_falg
        this.list = data.order
        console.log(data, '详情')
        this.loading = false
      },
      close() {
        this.showDialog = false
      },
      handleSubmit() {
        this.subForm.dealer_id = this.id
        console.log(this.subForm.mall_flag, '状态')
        let a = JSON.parse(JSON.stringify(this.subForm))
        if (a.port_add || a.port_gift || a.port_money) {
          a.bill_type.push(2)
        }
        if (a.time_add || a.time_gift || a.time_money) {
          a.bill_type.push(3)
        }
        if (a.mall_limit || a.mall_money) {
          a.bill_type.push(4)
        }
        if (a.teach_date || a.teach_user || a.teach_day || a.teach_money) {
          a.bill_type.push(5)
        }
        a.bill_type = a.bill_type.length == 0 ? '' : a.bill_type.join(',')
        console.log(a)
        userRenewalSubmit(a).then((res) => {
          this.$message.success(res.msg)
          this.close()
          this.$emit('refresh')
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .table {
    margin-bottom: 10px;
  }
</style>
