var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: " 延长试用期",
            visible: _vm.showDialog,
            width: "400px",
            top: "18vh",
            "close-on-click-modal": false,
            "destroy-on-close": "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "orderTest-container" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    inline: "",
                    "label-position": "right",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "延迟", prop: "" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.day,
                          callback: function ($$v) {
                            _vm.day = $$v
                          },
                          expression: "day",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px", "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确认")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }