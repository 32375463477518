<template>
  <div class="orderTest-container">
    <el-dialog
      :modal="false"
      title=" 延长试用期"
      :visible.sync="showDialog"
      width="400px"
      top="18vh"
      :close-on-click-modal="false"
      destroy-on-close
      append-to-body
    >
      <div class="orderTest-container">
        <el-form ref="form" inline label-position="right" label-width="80px">
          <el-form-item label="延迟" prop="">
            <el-input v-model="day"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-top: 10px; text-align: right">
        <el-button type="primary" @click="handleConfirm">确认</el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    name: 'Prolong',
    components: {},
    data() {
      return {
        day: '',
        userId: '',
        showDialog: false,
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (!val) {
          this.day = ''
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      handleConfirm() {
        this.$emit('handleProlong', { day: this.day, userId: this.userId })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
