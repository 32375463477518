var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: _vm.title,
            visible: _vm.showDialog,
            width: "1000px",
            top: "5vh",
            "close-on-click-modal": false,
            "destroy-on-close": "",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "orderTest-container" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    inline: "",
                    "label-position": "right",
                    "label-width": "10px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "depart_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { clearable: "", placeholder: "部门" },
                          model: {
                            value: _vm.form.depart_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "depart_id", $$v)
                            },
                            expression: "form.depart_id",
                          },
                        },
                        _vm._l(_vm.departmentSelectList, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.text },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "role" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { clearable: "", placeholder: "角色" },
                          model: {
                            value: _vm.form.role,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "role", $$v)
                            },
                            expression: "form.role",
                          },
                        },
                        _vm._l(_vm.roleSelectList, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.title },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "keyword" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "姓名、手机号" },
                        model: {
                          value: _vm.form.keyword,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "keyword", $$v)
                          },
                          expression: "form.keyword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { clearable: "", placeholder: "状态" },
                          model: {
                            value: _vm.form.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status",
                          },
                        },
                        _vm._l(_vm.statusList, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.fetchData },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { stripe: "", data: _vm.list, height: "500px" },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", type: "selection", width: "50" },
                  }),
                  _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: "",
                        align: "center",
                      },
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      align: "center",
                      label: "操作",
                      fixed: "right",
                      "min-width": "140px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            row.status == 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.handleEnable($index, row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 启用 ")]
                                )
                              : _vm._e(),
                            row.status == 2
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.handleCancel($index, row)
                                      },
                                    },
                                  },
                                  [_vm._v(" 封存 ")]
                                )
                              : _vm._e(),
                            row.status != 1 || row.status != 2
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleProlong($index, row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 延长 ")]
                                  ),
                                  row.status == 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleToFormal(
                                                $index,
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 转正式 ")]
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticStyle: { "margin-top": "10px", "text-align": "right" },
                },
                [
                  _c("el-button", { attrs: { type: "warning" } }, [
                    _vm._v("封存"),
                  ]),
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("关闭"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("prolong", { ref: "prolong", on: { handleProlong: _vm.prolong } }),
          _c("Edit", { ref: "edit", on: { refresh: _vm.fetchData } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }