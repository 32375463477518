<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :disabled="type == 3 ? true : false"
      :model="form"
      :rules="rules"
      inline
      label-position="right"
      label-width="95px"
      style="margin-bottom: 15px"
    >
      <el-form-item label="企业名称:" prop="dealer_name">
        <el-input v-model="form_.dealer_name"></el-input>
      </el-form-item>
      <el-form-item label="ID:" prop="id">
        <el-input v-model="form_.id" disabled></el-input>
      </el-form-item>
      <el-form-item label="联系人:" prop="boss">
        <el-input v-model="form_.boss"></el-input>
      </el-form-item>
      <el-form-item label="手机:" prop="mobile">
        <el-input v-model="form_.mobile"></el-input>
      </el-form-item>
      <el-form-item label="电话:" prop="phone">
        <el-input v-model="form_.phone"></el-input>
      </el-form-item>
      <el-form-item label="规模:" prop="guimo">
        <!-- <el-input v-model="form_.guimo"></el-input> -->
        <el-select
          v-model="form_.guimo"
          clearable
          placeholder="规模"
          style="width: 200px"
        >
          <el-option
            v-for="(i, idx) in guimoList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="机构类型:" prop="leixing">
        <el-select
          v-model="form_.leixing"
          clearable
          placeholder="机构类型"
          style="width: 200px"
        >
          <el-option
            v-for="(i, idx) in leixingList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="行业:" prop="hangye">
        <!-- <el-input v-model="form_.hangye"></el-input> -->
        <el-select
          v-model="form_.hangye"
          clearable
          placeholder="行业"
          multiple
          style="width: 200px"
        >
          <el-option
            v-for="(i, idx) in hangyeList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在地:" prop="local" style="width: 100%">
        <el-select
          v-model="form_.province"
          clearable
          style="width: 100px; margin-right: 15px"
          placeholder="省"
          @change="shengChange"
        >
          <el-option
            v-for="(i, idx) in shengList"
            :key="idx"
            :value="i.id"
            :label="i.title"
          ></el-option>
        </el-select>
        <el-select
          v-model="form_.city"
          clearable
          style="width: 100px; margin-right: 15px"
          placeholder="市"
          @change="shiChange"
        >
          <el-option
            v-for="(i, idx) in shiList"
            :key="idx"
            :value="i.id"
            :label="i.title"
          ></el-option>
        </el-select>
        <el-select
          v-model="form_.area"
          clearable
          style="width: 100px; margin-right: 15px"
          placeholder="区"
        >
          <el-option
            v-for="(i, idx) in quList"
            :key="idx"
            :value="i.id"
            :label="i.title"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="地址:" prop="address">
        <el-input v-model="form_.address" style="width: 504px"></el-input>
      </el-form-item>
      <div></div>
      <el-form-item label="来源:" prop="from">
        <el-select
          v-model="form_.from"
          clearable
          style="width: 200px"
          placeholder="来源"
        >
          <el-option
            v-for="(i, idx) in fromList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="推荐人:" prop="parent_name">
        <el-input v-model="form_.parent_name"></el-input>
      </el-form-item>
      <el-form-item label="归属:" prop="vest_in">
        <!-- <el-input v-model="form_.vest_in"></el-input> -->
        <el-select
          v-model="form_.vest_in"
          clearable
          style="width: 140px"
          placeholder="归属"
        >
          <el-option
            v-for="(i, idx) in vestList"
            :key="idx"
            :value="i.id"
            :label="i.truename"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="管理员账号:" prop="login_user">
        <el-input v-model="form_.login_user"></el-input>
      </el-form-item>
      <el-form-item label="密码:" prop="password">
        <el-input v-model="form_.password" type="password"></el-input>
      </el-form-item>
      <div></div>
      <el-form-item label="开始日期:" prop="start_time">
        <!-- <el-input v-model="form_.start_time"></el-input> -->
        <el-date-picker
          v-model="form_.start_time"
          type="date"
          placeholder="开始日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          style="width: 200px"
          :disabled="type == 2 ? true : false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期:" prop="end_time">
        <!-- <el-input v-model="form_.end_time"></el-input> -->
        <el-date-picker
          v-model="form_.end_time"
          type="date"
          placeholder="结束日期"
          :disabled="form.can_check == 0 ? true : false"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          style="width: 200px"
        ></el-date-picker>
      </el-form-item>
      <div></div>
      <slot :type="type"></slot>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form_.remark"
          placeholder="备注"
          style="width: 504px"
        ></el-input>
      </el-form-item>
      <div style="display: flex; margin-left: 100px">
        <!-- ckzx_falg  0关1开 -->
        <el-switch
          v-model="form_.ckzx_falg"
          style="display: block"
          :active-value="1"
          :inactive-value="0"
          active-color="#13ce66"
          active-text="仓库直销可选业务员"
        ></el-switch>
        <el-switch
          v-model="form_.photo_flag"
          style="display: block"
          :active-value="1"
          :inactive-value="0"
          active-color="#13ce66"
          active-text="单据拍照功能（访销、车销、调拨单）"
        ></el-switch>
      </div>
    </el-form>
  </div>
</template>
<script>
  import {
    hangyeSelectList,
    localSelectList,
    getId,
    guishuSelectList,
  } from '@/api/admin/userManagement'
  export default {
    name: 'TopForm',
    components: {},
    props: {
      type: {
        type: Number,
        default: () => 1,
      },
      form: {
        type: Object,
        default: function () {
          return {
            dealer_name: '',
            id: '',
            boss: '',
            mobile: '',
            phone: '',
            guimo: '',
            leixing: '',
            hangye: '',
            province: '',
            city: '',
            area: '',
            address: '',
            login_user: '',
            password: '',
            start_time: '',
            end_time: '',
            from: '',
            parent_name: '',
            vest_in: '',
          }
        },
      },
    },
    data() {
      return {
        // //1 添加 2 编辑 3 查看
        // type: 1,
        formSucc: false,
        form_: this.form,
        // 1(20人)2(20-50人)3(50-100人)4(100人以上)
        guimoList: [
          {
            id: '1',
            name: '20人',
          },
          {
            id: '2',
            name: '20-50人',
          },
          {
            id: '3',
            name: '50-100人',
          },
          {
            id: '4',
            name: '100人以上',
          },
        ],
        // 类型 1生产企业、2集团经销商、3地区经销商
        leixingList: [
          {
            id: '1',
            name: '生产企业',
          },
          {
            id: '2',
            name: '集团经销商',
          },
          {
            id: '3',
            name: '地区经销商',
          },
        ],
        // 行业 逗号隔开如：1,2,3 取值从行业列表
        hangyeList: [],
        // 来源 1APP注册2PC注册3工作站4转介绍
        fromList: [
          {
            id: '1',
            name: 'APP注册',
          },
          {
            id: '2',
            name: 'PC注册',
          },
          {
            id: '3',
            name: '工作站',
          },
          {
            id: '4',
            name: '转介绍',
          },
        ],
        // 归属
        vestList: [],
        shengList: [],
        shiList: [],
        quList: [],
        local: 0,
        rules: {
          dealer_name: [
            { required: true, message: '请输入企业名称', trigger: 'blur' },
          ],
          boss: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
          phone: [{ required: true, message: '请输入手机', trigger: 'blur' }],
        },
      }
    },
    computed: {},
    watch: {
      form(val) {
        this.form_ = val
      },
    },
    created() {
      this.initSelect()
      this.initLocalSelect()
      this.getFormId()
      this.guishuList()
    },
    mounted() {},
    methods: {
      async initSelect() {
        let { data } = await hangyeSelectList()
        this.hangyeList = data
      },
      async guishuList() {
        let { data } = await guishuSelectList({ pageSize: -1 })
        this.vestList = data
      },
      async initLocalSelect() {
        let { data } = await localSelectList({ parent_id: this.local })
        this.shengList = data
      },
      async getFormId() {
        let { data } = await getId()
        this.form_.id = data
      },
      shengChange(val) {
        this.form_.city = ''
        this.form_.area = ''
        this.shiList = []
        this.quList = []
        console.log(val)

        if (val) {
          localSelectList({ parent_id: val }).then((res) => {
            this.shiList = res.data
          })
        }
      },
      shiChange(val) {
        this.form_.area = ''
        this.quList = []
        console.log(val)
        if (val) {
          localSelectList({ parent_id: val }).then((res) => {
            this.quList = res.data
          })
        }
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.form_.province = ''
        this.form_.city = ''
        this.form_.area = ''
      },
      formatForm() {
        this.$refs.form.validate((val) => {
          if (val) {
            this.formSucc = true
            // return 111
          } else {
            this.formSucc = false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
