var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: "用户续费",
            visible: _vm.showDialog,
            width: "1000px",
            top: "5vh",
            "close-on-click-modal": false,
            "destroy-on-close": "",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "orderTest-container" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    inline: "",
                    "label-position": "right",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业名称:", prop: "dealer_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.dealer_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "dealer_name", $$v)
                          },
                          expression: "form.dealer_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "ID:", prop: "dealer_id" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.dealer_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "dealer_id", $$v)
                          },
                          expression: "form.dealer_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开通日期:", prop: "start_time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          disabled: "",
                          placeholder: "开通日期",
                          format: "yyyy 年 MM 月 dd 日",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.form.start_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "start_time", $$v)
                          },
                          expression: "form.start_time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "到期日期:", prop: "end_time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          disabled: "",
                          placeholder: "到期日期",
                          format: "yyyy 年 MM 月 dd 日",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.form.end_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "end_time", $$v)
                          },
                          expression: "form.end_time",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "table",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "line-height": "35px",
                        color: "'#606266'",
                        "background-color": "#f5f7fa",
                      },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [_vm._v("类型1")]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _vm._v("现有数量"),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _vm._v("增加数量"),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _vm._v("赠送数量"),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [_vm._v("金额")]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content",
                            staticStyle: { "line-height": "35px" },
                          },
                          [_vm._v("添加端口")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content",
                            staticStyle: { "line-height": "35px" },
                          },
                          [_vm._v(" " + _vm._s(_vm.form.account_num) + " ")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.subForm.port_add,
                                callback: function ($$v) {
                                  _vm.$set(_vm.subForm, "port_add", $$v)
                                },
                                expression: "subForm.port_add",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.subForm.port_gift,
                                callback: function ($$v) {
                                  _vm.$set(_vm.subForm, "port_gift", $$v)
                                },
                                expression: "subForm.port_gift",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.subForm.port_money,
                                callback: function ($$v) {
                                  _vm.$set(_vm.subForm, "port_money", $$v)
                                },
                                expression: "subForm.port_money",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "table",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "line-height": "35px",
                        color: "'#606266'",
                        "background-color": "#f5f7fa",
                      },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [_vm._v("类型2")]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _vm._v("当前到期时间"),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _vm._v("续期(月)"),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _vm._v("赠送(月)"),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [_vm._v("金额")]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content",
                            staticStyle: { "line-height": "35px" },
                          },
                          [_vm._v("续期")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content",
                            staticStyle: { "line-height": "35px" },
                          },
                          [_vm._v(" " + _vm._s(_vm.form.end_time) + " ")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.subForm.time_add,
                                callback: function ($$v) {
                                  _vm.$set(_vm.subForm, "time_add", $$v)
                                },
                                expression: "subForm.time_add",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.subForm.time_gift,
                                callback: function ($$v) {
                                  _vm.$set(_vm.subForm, "time_gift", $$v)
                                },
                                expression: "subForm.time_gift",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.subForm.time_money,
                                callback: function ($$v) {
                                  _vm.$set(_vm.subForm, "time_money", $$v)
                                },
                                expression: "subForm.time_money",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "table",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "line-height": "35px",
                        color: "'#606266'",
                        "background-color": "#f5f7fa",
                      },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [_vm._v("类型3")]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _vm._v("网销开关"),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _vm._v("现门店上限"),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [_vm._v("升级后")]),
                      _c("el-col", { attrs: { span: 5 } }, [_vm._v("金额")]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content",
                            staticStyle: { "line-height": "35px" },
                          },
                          [_vm._v("网销升级")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-value": 1,
                                "inactive-value": 0,
                                "active-color": "#13ce66",
                                "inactive-color": "#ff4949",
                              },
                              model: {
                                value: _vm.subForm.mall_falg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.subForm, "mall_falg", $$v)
                                },
                                expression: "subForm.mall_falg",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content",
                            staticStyle: { "line-height": "35px" },
                          },
                          [_vm._v(" " + _vm._s(_vm.form.mall_limit) + " ")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.subForm.mall_limit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.subForm, "mall_limit", $$v)
                                },
                                expression: "subForm.mall_limit",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.subForm.mall_money,
                                callback: function ($$v) {
                                  _vm.$set(_vm.subForm, "mall_money", $$v)
                                },
                                expression: "subForm.mall_money",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "table",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "line-height": "35px",
                        color: "'#606266'",
                        "background-color": "#f5f7fa",
                      },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [_vm._v("类型4")]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _vm._v("培训日期"),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [_vm._v("培训人")]),
                      _c("el-col", { attrs: { span: 5 } }, [_vm._v("天数")]),
                      _c("el-col", { attrs: { span: 5 } }, [_vm._v("金额")]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content",
                            staticStyle: { "line-height": "35px" },
                          },
                          [_vm._v("培训")]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "grid-content",
                            staticStyle: { "line-height": "35px" },
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "date",
                                placeholder: "培训日期",
                                format: "yyyy 年 MM 月 dd 日",
                                "value-format": "yyyy-MM-dd",
                              },
                              model: {
                                value: _vm.subForm.teach_date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.subForm, "teach_date", $$v)
                                },
                                expression: "subForm.teach_date",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "", placeholder: "培训人" },
                                model: {
                                  value: _vm.subForm.teach_user,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.subForm, "teach_user", $$v)
                                  },
                                  expression: "subForm.teach_user",
                                },
                              },
                              _vm._l(_vm.peixunrenList, function (i, idx) {
                                return _c("el-option", {
                                  key: idx,
                                  attrs: { value: i.id, label: i.username },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.subForm.teach_day,
                                callback: function ($$v) {
                                  _vm.$set(_vm.subForm, "teach_day", $$v)
                                },
                                expression: "subForm.teach_day",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.subForm.teach_money,
                                callback: function ($$v) {
                                  _vm.$set(_vm.subForm, "teach_money", $$v)
                                },
                                expression: "subForm.teach_money",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "margin-top": "10px", "text-align": "right" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSubmit },
                    },
                    [_vm._v("确定")]
                  ),
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { stripe: "", data: _vm.list },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "bill_code",
                      label: "订单号",
                      width: "",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_at",
                      label: "收款日期",
                      width: "",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "total_amount",
                      label: "金额",
                      width: "",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }