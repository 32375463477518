<template>
  <div class="orderTest-container">
    <el-dialog
      :modal="false"
      :title="title"
      :visible.sync="showDialog"
      width="1000px"
      top="5vh"
      :close-on-click-modal="false"
      destroy-on-close
      center
    >
      <div class="orderTest-container">
        <el-form
          ref="form"
          :model="form"
          inline
          label-position="right"
          label-width="10px"
        >
          <el-form-item label="" prop="depart_id">
            <el-select
              v-model="form.depart_id"
              clearable
              style="width: 140px"
              placeholder="部门"
            >
              <el-option
                v-for="(i, idx) in departmentSelectList"
                :key="idx"
                :value="i.id"
                :label="i.text"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="role">
            <el-select
              v-model="form.role"
              clearable
              style="width: 140px"
              placeholder="角色"
            >
              <el-option
                v-for="(i, idx) in roleSelectList"
                :key="idx"
                :value="i.id"
                :label="i.title"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="keyword">
            <el-input
              v-model="form.keyword"
              placeholder="姓名、手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="status">
            <el-select
              v-model="form.status"
              clearable
              style="width: 140px"
              placeholder="状态"
            >
              <el-option
                v-for="(i, idx) in statusList"
                :key="idx"
                :value="i.id"
                :label="i.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="">
            <el-button type="primary" @click="fetchData">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table v-loading="loading" stripe :data="list" height="500px">
          <!-- 选择框 -->
          <el-table-column
            align="center"
            type="selection"
            width="50"
          ></el-table-column>
          <el-table-column
            v-for="(item, tableIndex) in finallyColumns"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            width=""
            align="center"
          ></el-table-column>
          <el-table-column
            prop=""
            align="center"
            label="操作"
            fixed="right"
            min-width="140px"
          >
            <template #default="{ $index, row }">
              <el-button type="text" @click="handleEdit(row)">编辑</el-button>
              <!-- 1封存 -->
              <el-button
                v-if="row.status == 1"
                type="text"
                @click.native.prevent="handleEnable($index, row)"
              >
                启用
              </el-button>
              <el-button
                v-if="row.status == 2"
                type="text"
                @click.native.prevent="handleCancel($index, row)"
              >
                封存
              </el-button>
              <template v-if="row.status != 1 || row.status != 2">
                <el-button
                  type="text"
                  @click.native.prevent="handleProlong($index, row)"
                >
                  延长
                </el-button>
                <el-button
                  v-if="row.status == 0"
                  type="text"
                  @click.native.prevent="handleToFormal($index, row)"
                >
                  转正式
                </el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px; text-align: right">
          <el-button type="warning">封存</el-button>
          <el-button @click="close">关闭</el-button>
        </div>
      </div>
      <prolong ref="prolong" @handleProlong="prolong"></prolong>
      <Edit ref="edit" @refresh="fetchData"></Edit>
    </el-dialog>
  </div>
</template>
<script>
  import _ from 'lodash'
  import Edit from './edit'
  // 部门下拉
  import { departmentList } from '@/api/performance'
  // 角色下拉
  import {
    roleList,
    formalUserStaffList,
    staffToFormal,
    staffProlong,
    staffDisable,
    staffEnable,
  } from '@/api/admin/userManagement'
  import Prolong from './prolong.vue'

  export default {
    name: 'Staff',
    components: { Prolong, Edit },
    data() {
      return {
        id: 0,
        title: '员工',
        showDialog: false,
        loading: false,
        form: {
          depart_id: '',
          role: '',
          keyword: '',
          status: '',
          // 经销商ID
          dealer_id: '',
        },
        // 部门下拉
        departmentSelectList: [],
        // 角色下拉
        roleSelectList: [],

        // 状态 1封存 2正常 3试用
        statusList: [
          {
            id: '1',
            name: '封存',
          },
          {
            id: '2',
            name: '正常',
          },
          {
            id: '3',
            name: '试用',
          },
        ],
        list: [],
        checkList: [
          '姓名',
          '登录手机',
          '角色',
          '类型',
          '部门',
          '创建日期',
          '到期日期',
        ],
        columns: [
          {
            order: 1,
            label: '姓名',
            prop: 'user_name',
            width: '',
          },
          {
            order: 2,
            label: '登录手机',
            prop: 'mobile',
            width: '',
          },
          {
            order: 3,
            label: '角色',
            prop: 'user_role',
            width: '',
          },
          {
            order: 4,
            label: '类型',
            prop: 'status_text',
            width: '',
          },
          {
            order: 5,
            label: '部门',
            prop: 'depart_name',
            width: '',
          },
          {
            order: 6,
            label: '创建日期',
            prop: 'create_at',
            width: '',
          },
          {
            order: 7,
            label: '到期日期',
            prop: 'end_time',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.$emit('refresh')
        }
      },
    },
    created() {},
    mounted() {
      this.initSelect()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await formalUserStaffList(this.form)
        this.list = data
        if (this.list.length != 0) {
          this.title =
            '企业:' +
            this.list[0].depart_name +
            ' (id:' +
            this.list[0].depart_id +
            ')'
        }
        console.log(data)

        this.loading = false
      },
      initSelect() {
        departmentList().then((res) => {
          this.departmentSelectList = res.data.list
        })
        roleList().then((res) => {
          this.roleSelectList = res.data
        })
      },
      close() {
        this.showDialog = false
      },
      handleEnable(index, row) {
        staffEnable({ id: row.user_id }).then((res) => {
          this.$message.success(res.msg)
          this.fetchData()
        })
      },
      handleCancel(index, row) {
        staffDisable({ id: row.user_id }).then((res) => {
          this.$message.success(res.msg)
          this.fetchData()
        })
      },
      handleProlong(index, row) {
        this.$refs.prolong.userId = row.user_id
        this.$refs.prolong.showDialog = true
      },
      handleToFormal(index, row) {
        staffToFormal({ id: row.user_id }).then((res) => {
          this.$message.success(res.msg)
          this.fetchData()
        })
      },
      prolong(val) {
        console.log('val', val)
        staffProlong({ id: val.userId, day_num: val.day }).then((res) => {
          this.$message.success(res.msg)
          this.$refs.prolong.showDialog = false
          this.fetchData()
        })
      },
      handleEdit(row) {
        this.$refs.edit.dealer_id = this.form.dealer_id
        this.$refs.edit.user_id = row.user_id
        this.$refs.edit.handlerData(row)
        this.$refs.edit.dialogVisible = true
      },
    },
  }
</script>
<style lang="scss" scoped></style>
