var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c("top-form", {
            ref: "topForm",
            attrs: { form: _vm.topForm, type: 2 },
            on: {
              "update:form": function ($event) {
                _vm.topForm = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (slot) {
                  return [
                    _c(
                      "el-form-item",
                      { attrs: { label: "端口数上限", prop: "account_num" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: slot.type == 2 ? true : false },
                          model: {
                            value: _vm.topForm.account_num,
                            callback: function ($$v) {
                              _vm.$set(_vm.topForm, "account_num", $$v)
                            },
                            expression: "topForm.account_num",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div"),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-right": "170px" },
                        attrs: { label: "网销开关", prop: "mall_falg" },
                      },
                      [
                        _c("el-switch", {
                          attrs: {
                            disabled: "",
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                          },
                          model: {
                            value: _vm.topForm.mall_falg,
                            callback: function ($$v) {
                              _vm.$set(_vm.topForm, "mall_falg", $$v)
                            },
                            expression: "topForm.mall_falg",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "门店上限", prop: "mall_limit" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: slot.type == 2 ? true : false },
                          model: {
                            value: _vm.topForm.mall_limit,
                            callback: function ($$v) {
                              _vm.$set(_vm.topForm, "mall_limit", $$v)
                            },
                            expression: "topForm.mall_limit",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.hanldeSave } },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }