var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c("top-form", { ref: "topForm", attrs: { form: _vm.topForm } }),
          _c(
            "div",
            { staticClass: "table", staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "line-height": "35px",
                    color: "'#606266'",
                    "background-color": "#f5f7fa",
                  },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("类型1")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("开通数量")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("赠送数量")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("试用上限")]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [_vm._v("开通端口")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.topForm.port_add,
                            callback: function ($$v) {
                              _vm.$set(_vm.topForm, "port_add", $$v)
                            },
                            expression: "topForm.port_add",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.topForm.port_gift,
                            callback: function ($$v) {
                              _vm.$set(_vm.topForm, "port_gift", $$v)
                            },
                            expression: "topForm.port_gift",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.topForm.port_test,
                            callback: function ($$v) {
                              _vm.$set(_vm.topForm, "port_test", $$v)
                            },
                            expression: "topForm.port_test",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table", staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "line-height": "35px",
                    color: "'#606266'",
                    "background-color": "#f5f7fa",
                  },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("类型2")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("时长(月)")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("赠送(月)")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("金额")]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [_vm._v("签约时长")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.topForm.time_add,
                            callback: function ($$v) {
                              _vm.$set(_vm.topForm, "time_add", $$v)
                            },
                            expression: "topForm.time_add",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.topForm.time_gift,
                            callback: function ($$v) {
                              _vm.$set(_vm.topForm, "time_gift", $$v)
                            },
                            expression: "topForm.time_gift",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.topForm.time_money,
                            callback: function ($$v) {
                              _vm.$set(_vm.topForm, "time_money", $$v)
                            },
                            expression: "topForm.time_money",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table", staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "line-height": "35px",
                    color: "'#606266'",
                    "background-color": "#f5f7fa",
                  },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("类型3")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("网销开关")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("门店上限")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("金额")]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [_vm._v("网销")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                          },
                          model: {
                            value: _vm.topForm.mall_falg,
                            callback: function ($$v) {
                              _vm.$set(_vm.topForm, "mall_falg", $$v)
                            },
                            expression: "topForm.mall_falg",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.topForm.mall_limit,
                            callback: function ($$v) {
                              _vm.$set(_vm.topForm, "mall_limit", $$v)
                            },
                            expression: "topForm.mall_limit",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.topForm.mall_money,
                            callback: function ($$v) {
                              _vm.$set(_vm.topForm, "mall_money", $$v)
                            },
                            expression: "topForm.mall_money",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table", staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "line-height": "35px",
                    color: "'#606266'",
                    "background-color": "#f5f7fa",
                  },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("类型4")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("培训日期")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("培训人")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("天数")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("金额")]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [_vm._v("培训")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy 年 MM 月 dd 日",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.topForm.teach_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.topForm, "teach_date", $$v)
                            },
                            expression: "topForm.teach_date",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { clearable: "", placeholder: "培训人" },
                            model: {
                              value: _vm.topForm.teach_user,
                              callback: function ($$v) {
                                _vm.$set(_vm.topForm, "teach_user", $$v)
                              },
                              expression: "topForm.teach_user",
                            },
                          },
                          _vm._l(_vm.peixunrenList, function (i, idx) {
                            return _c("el-option", {
                              key: idx,
                              attrs: { value: i.id, label: i.username },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.topForm.teach_day,
                            callback: function ($$v) {
                              _vm.$set(_vm.topForm, "teach_day", $$v)
                            },
                            expression: "topForm.teach_day",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.topForm.teach_money,
                            callback: function ($$v) {
                              _vm.$set(_vm.topForm, "teach_money", $$v)
                            },
                            expression: "topForm.teach_money",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.hanldeCreate } },
            [_vm._v("创建")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }