var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-bottom": "15px" },
          attrs: {
            disabled: _vm.type == 3 ? true : false,
            model: _vm.form,
            rules: _vm.rules,
            inline: "",
            "label-position": "right",
            "label-width": "95px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "企业名称:", prop: "dealer_name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form_.dealer_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form_, "dealer_name", $$v)
                  },
                  expression: "form_.dealer_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "ID:", prop: "id" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.form_.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form_, "id", $$v)
                  },
                  expression: "form_.id",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系人:", prop: "boss" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form_.boss,
                  callback: function ($$v) {
                    _vm.$set(_vm.form_, "boss", $$v)
                  },
                  expression: "form_.boss",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机:", prop: "mobile" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form_.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.form_, "mobile", $$v)
                  },
                  expression: "form_.mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "电话:", prop: "phone" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form_.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form_, "phone", $$v)
                  },
                  expression: "form_.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "规模:", prop: "guimo" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: { clearable: "", placeholder: "规模" },
                  model: {
                    value: _vm.form_.guimo,
                    callback: function ($$v) {
                      _vm.$set(_vm.form_, "guimo", $$v)
                    },
                    expression: "form_.guimo",
                  },
                },
                _vm._l(_vm.guimoList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "机构类型:", prop: "leixing" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: { clearable: "", placeholder: "机构类型" },
                  model: {
                    value: _vm.form_.leixing,
                    callback: function ($$v) {
                      _vm.$set(_vm.form_, "leixing", $$v)
                    },
                    expression: "form_.leixing",
                  },
                },
                _vm._l(_vm.leixingList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "行业:", prop: "hangye" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: { clearable: "", placeholder: "行业", multiple: "" },
                  model: {
                    value: _vm.form_.hangye,
                    callback: function ($$v) {
                      _vm.$set(_vm.form_, "hangye", $$v)
                    },
                    expression: "form_.hangye",
                  },
                },
                _vm._l(_vm.hangyeList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "100%" },
              attrs: { label: "所在地:", prop: "local" },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100px", "margin-right": "15px" },
                  attrs: { clearable: "", placeholder: "省" },
                  on: { change: _vm.shengChange },
                  model: {
                    value: _vm.form_.province,
                    callback: function ($$v) {
                      _vm.$set(_vm.form_, "province", $$v)
                    },
                    expression: "form_.province",
                  },
                },
                _vm._l(_vm.shengList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.title },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100px", "margin-right": "15px" },
                  attrs: { clearable: "", placeholder: "市" },
                  on: { change: _vm.shiChange },
                  model: {
                    value: _vm.form_.city,
                    callback: function ($$v) {
                      _vm.$set(_vm.form_, "city", $$v)
                    },
                    expression: "form_.city",
                  },
                },
                _vm._l(_vm.shiList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.title },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100px", "margin-right": "15px" },
                  attrs: { clearable: "", placeholder: "区" },
                  model: {
                    value: _vm.form_.area,
                    callback: function ($$v) {
                      _vm.$set(_vm.form_, "area", $$v)
                    },
                    expression: "form_.area",
                  },
                },
                _vm._l(_vm.quList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.title },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "地址:", prop: "address" } },
            [
              _c("el-input", {
                staticStyle: { width: "504px" },
                model: {
                  value: _vm.form_.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form_, "address", $$v)
                  },
                  expression: "form_.address",
                },
              }),
            ],
            1
          ),
          _c("div"),
          _c(
            "el-form-item",
            { attrs: { label: "来源:", prop: "from" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: { clearable: "", placeholder: "来源" },
                  model: {
                    value: _vm.form_.from,
                    callback: function ($$v) {
                      _vm.$set(_vm.form_, "from", $$v)
                    },
                    expression: "form_.from",
                  },
                },
                _vm._l(_vm.fromList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "推荐人:", prop: "parent_name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form_.parent_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form_, "parent_name", $$v)
                  },
                  expression: "form_.parent_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "归属:", prop: "vest_in" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "归属" },
                  model: {
                    value: _vm.form_.vest_in,
                    callback: function ($$v) {
                      _vm.$set(_vm.form_, "vest_in", $$v)
                    },
                    expression: "form_.vest_in",
                  },
                },
                _vm._l(_vm.vestList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.truename },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "管理员账号:", prop: "login_user" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form_.login_user,
                  callback: function ($$v) {
                    _vm.$set(_vm.form_, "login_user", $$v)
                  },
                  expression: "form_.login_user",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "密码:", prop: "password" } },
            [
              _c("el-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.form_.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.form_, "password", $$v)
                  },
                  expression: "form_.password",
                },
              }),
            ],
            1
          ),
          _c("div"),
          _c(
            "el-form-item",
            { attrs: { label: "开始日期:", prop: "start_time" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "200px" },
                attrs: {
                  type: "date",
                  placeholder: "开始日期",
                  format: "yyyy 年 MM 月 dd 日",
                  "value-format": "yyyy-MM-dd",
                  disabled: _vm.type == 2 ? true : false,
                },
                model: {
                  value: _vm.form_.start_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.form_, "start_time", $$v)
                  },
                  expression: "form_.start_time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "结束日期:", prop: "end_time" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "200px" },
                attrs: {
                  type: "date",
                  placeholder: "结束日期",
                  disabled: _vm.form.can_check == 0 ? true : false,
                  format: "yyyy 年 MM 月 dd 日",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.form_.end_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.form_, "end_time", $$v)
                  },
                  expression: "form_.end_time",
                },
              }),
            ],
            1
          ),
          _c("div"),
          _vm._t("default", null, { type: _vm.type }),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                staticStyle: { width: "504px" },
                attrs: { placeholder: "备注" },
                model: {
                  value: _vm.form_.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form_, "remark", $$v)
                  },
                  expression: "form_.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex", "margin-left": "100px" } },
            [
              _c("el-switch", {
                staticStyle: { display: "block" },
                attrs: {
                  "active-value": 1,
                  "inactive-value": 0,
                  "active-color": "#13ce66",
                  "active-text": "仓库直销可选业务员",
                },
                model: {
                  value: _vm.form_.ckzx_falg,
                  callback: function ($$v) {
                    _vm.$set(_vm.form_, "ckzx_falg", $$v)
                  },
                  expression: "form_.ckzx_falg",
                },
              }),
              _c("el-switch", {
                staticStyle: { display: "block" },
                attrs: {
                  "active-value": 1,
                  "inactive-value": 0,
                  "active-color": "#13ce66",
                  "active-text": "单据拍照功能（访销、车销、调拨单）",
                },
                model: {
                  value: _vm.form_.photo_flag,
                  callback: function ($$v) {
                    _vm.$set(_vm.form_, "photo_flag", $$v)
                  },
                  expression: "form_.photo_flag",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }