<template>
  <el-dialog
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div v-loading="loading" class="orderTest-container">
      <top-form ref="topForm" :form.sync="topForm" :type="3">
        <template slot-scope="slot">
          <el-form-item label="端口数上限" prop="account_num">
            <el-input
              v-model="topForm.account_num"
              :disabled="slot.type == 2 ? true : false"
            ></el-input>
          </el-form-item>
          <div></div>
          <!-- 0关1开 -->
          <el-form-item
            style="margin-right: 170px"
            label="网销开关"
            prop="mall_falg"
          >
            <el-switch
              v-model="topForm.mall_falg"
              :disabled="slot.type == 2 ? true : false"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </el-form-item>
          <el-form-item label="门店上限" prop="mall_limit">
            <el-input
              v-model="topForm.mall_limit"
              :disabled="slot.type == 2 ? true : false"
            ></el-input>
          </el-form-item>
        </template>
      </top-form>
      <el-table stripe :data="list">
        <el-table-column
          prop="bill_code"
          label="订单号"
          width=""
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_at"
          label="收款日期"
          width=""
          align="center"
        ></el-table-column>
        <el-table-column
          prop="total_amount"
          label="金额"
          width=""
          align="center"
        ></el-table-column>
      </el-table>
    </div>
    <div slot="footer" style="margin-top: 5px; text-align: right">
      <el-button type="primary" @click="hanldeSave">保存</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import {
    formalUserDetail,
    localSelectList,
    formalUserEdit,
  } from '@/api/admin/userManagement'
  import TopForm from './topForm'
  export default {
    name: 'Check',
    components: { TopForm },
    data() {
      return {
        id: 0,
        loading: false,
        title: '查看正式用户',
        showDialog: false,
        list: [],
        topForm: {
          dealer_name: '',
          id: '',
          boss: '',
          mobile: '',
          phone: '',
          guimo: '',
          leixing: '',
          hangye: '',
          province: '',
          city: '',
          area: '',
          address: '',
          login_user: '',
          password: '',
          start_time: '',
          end_time: '',
          from: '',
          parent_name: '',
          vest_in: '',
          account_num: '',
          mall_falg: '',
          mall_limit: '',
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.topForm = {
            dealer_name: '',
            id: '',
            account_num: '',
            boss: '',
            mobile: '',
            phone: '',
            guimo: '',
            leixing: '',
            hangye: '',
            province: '',
            city: '',
            area: '',
            address: '',
            login_user: '',
            password: '',
            start_time: '',
            end_time: '',
            from: '',
            parent_name: '',
            vest_in: '',
            mall_falg: '',
            mall_limit: '',
          }
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await formalUserDetail({ dealer_id: this.id })
        console.log('正式用户详细', data)
        this.topForm = data
        this.list = data.order
        this.topForm.id = data.dealer_id
        if (this.list.length != 0) {
          this.list.push({
            bill_code: '合计',
            create_at: '',
            total_amount: data.heji,
          })
        }
        this.topForm.vest_in =
          this.topForm.vest_in == '0' ? '' : this.topForm.vest_in
        this.topForm.hangye = this.topForm.hangye.split(',')
        this.initCityList()
      },
      // 初始化市区下拉
      async initCityList() {
        // var { data } = await localSelectList({
        //   parent_id: this.topForm.province,
        // })
        // this.$refs.topForm.shengList = data
        var { data } = await localSelectList({
          parent_id: this.topForm.province,
        })
        this.$refs.topForm.shiList = data
        var { data } = await localSelectList({ parent_id: this.topForm.city })
        this.$refs.topForm.quList = data
        this.loading = false
      },
      hanldeSave() {
        let data = JSON.parse(JSON.stringify(this.topForm))
        data.hangye = data.hangye.join(',')
        formalUserEdit(data).then((res) => {
          this.$message.success(res.msg)
          this.close()
        })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
